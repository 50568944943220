import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
	typography: {
		fontFamily: 'Inter',
		h1: {
			'fontFamily': 'Vogie',
			'fontSize': '5rem',
			'@media (max-width:600px)': {
				fontSize: '2em',
			},
		},
		h2: {
			fontWeight: 600,
			fontSize: '2.5rem',
		},
		caption: {
			'fontSize': '1.2rem',
			'@media (max-width:600px)': {
				fontSize: '0.9em',
			},
		},
	},
	palette: {
		primary: {
			main: '#5344FA',
		},
		secondary: {
			main: '#61CE36',
		},
		error: {
			main: red.A400,
		},
	},
});

theme = responsiveFontSizes(theme);

export default theme;
