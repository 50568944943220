import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import theme from './theme';
import Navbar from './features/Layout/Navbar';
import Footer from './features/Layout/Footer';
import 'typeface-inter';
import './global.css';

export const wrapRootElement = ({ element }: { element: any }) => (
	<ThemeProvider theme={theme}>
		<CssBaseline />
		{element}
	</ThemeProvider>
);
export const wrapPageElement = ({ element }: { element: any }) => (
	<Stack flexDirection="column" width="100vw" height="100vh">
		<Navbar />
		<Container
			maxWidth={false}
			sx={{
				overflowY: 'auto',
				height: '100%',
				width: '100%',
				paddingLeft: '0 !important',
				paddingRight: '0 !important',
			}}
		>
			{element}
			<Footer />
		</Container>
	</Stack>
);
