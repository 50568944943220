import * as React from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import BaseButton from '@mui/base/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const CustomTextField = styled(TextField)({
	'input': {
		color: 'white',
	},
	'.MuiOutlinedInput-notchedOutline': {
		borderStyle: 'dashed',
	},
	'.MuiInputBase-root:not(.Mui-focused)': {
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: 'white',
		},
	},
	'label:not(.Mui-focused)': {
		color: 'white',
	},
});

const CustomFormButton = styled(BaseButton)(({ theme }) => ({
	'minHeight': '60px',
	'minWidth': '190px',
	'position': 'absolute',
	'display': 'flex',
	'alignItems': 'center',
	'justifyContent': 'center',
	'bottom': '0',
	'right': '0',
	'fontFamily': 'Vogie',
	'fontSize': ' 1.5rem',
	'lineHeight': '1.5',
	'backgroundColor': 'rgba(255, 255, 255, 1)',
	'color': theme.palette.primary.main,
	'fontweight': '600',
	'padding': '12px 36px',
	'cursor': 'pointer',
	'transition': 'all 150ms ease',
	'border': 'none',
	'textTransform': 'uppercase',
	'&:hover:not(:disabled)': {
		backgroundColor: 'rgba(255, 255, 255, 0.8)',
	},

	' &:active:not(:disabled)': {
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
	},

	'&.buttonClasses.focusVisible': {
		outline: 'none',
	},
	'&.buttonClasses.disabled': {
		opacity: '0.5',
		cursor: 'not-allowed',
	},
}));

const CustomFormButtonResponsive = styled(CustomFormButton)({
	position: 'relative',
	fontSize: ' 1.2rem',
	minHeight: '40px',
	minWidth: '100px',
});

enum ContactStatuses {
	AWAITING,
	SENDING,
	SENT,
	ERROR,
}

const submitLabels = {
	[ContactStatuses.AWAITING]: 'Envoyer',
	[ContactStatuses.SENDING]: <CircularProgress size={30} />,
	[ContactStatuses.SENT]: <CheckCircleIcon color="secondary" />,
	[ContactStatuses.ERROR]: <ErrorIcon color="error" />,
};

interface ContactForm {
	fullname: string;
	email: string;
	company?: string;
	phone?: string;
	location?: string;
	source?: string;
	description?: string;
}

const schema = z.object({
	fullname: z.string({ required_error: 'Comment devons-nous vous appeller ?' }),
	email: z
		.string({
			required_error: 'Sans email, vous contactez va être compliqué',
		})
		.email({
			message: 'Sans un email correct, vous contactez va être compliqué',
		}),
	company: z.string().optional(),
	phone: z.string().optional(),
	location: z.string().optional(),
	source: z.string().optional(),
	description: z.string().optional(),
});

export default function ContactForm() {
	const theme = useTheme();
	const isPhone = useMediaQuery(theme.breakpoints.down('md'));
	const [contactStatus, setContactStatus] = React.useState(
		ContactStatuses.AWAITING,
	);
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ContactForm>({
		defaultValues: {},
		resolver: zodResolver(schema),
	});

	const onSubmit = async (data: ContactForm) => {
		// if (contactStatus !== ContactStatuses.AWAITING) return;

		setContactStatus(ContactStatuses.SENDING);
		const { error } = await window
			.fetch(`/api/contact`, {
				method: `POST`,
				headers: {
					'content-type': 'application/json',
				},
				body: JSON.stringify(data),
			})
			.then(res => ({ error: false }))
			.catch(() => ({ error: true }));

		setContactStatus(error ? ContactStatuses.ERROR : ContactStatuses.SENT);
	};

	return (
		<>
			{isPhone ? (
				<Typography
					id="contact"
					variant="h4"
					textAlign="center"
					fontFamily="Vogie"
					sx={{ color: 'white', marginBottom: '0.5em' }}
				>
					NOUS CONTACTER
				</Typography>
			) : (
				<Typography id="contact" variant="h1" sx={{ color: 'white' }}>
					NOUS CONTACTER
				</Typography>
			)}

			<Stack
				flexDirection={{ md: 'row', xs: 'column' }}
				flexWrap="wrap"
				gap={{ md: '2em', xs: '1em' }}
				onSubmit={handleSubmit(onSubmit)}
				component="form"
			>
				<Controller
					name="fullname"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Nom complet*"
							error={!!errors.fullname?.message}
							helperText={errors.fullname?.message}
						/>
					)}
				/>

				<Controller
					name="company"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Société"
						/>
					)}
				/>

				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Email*"
							error={!!errors.email?.message}
							helperText={errors.email?.message}
						/>
					)}
				/>

				<Controller
					name="phone"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Téléphone"
						/>
					)}
				/>

				<Controller
					name="location"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Adresse"
						/>
					)}
				/>

				<Controller
					name="source"
					control={control}
					render={({ field }) => (
						<CustomTextField
							{...field}
							size={isPhone ? 'small' : 'medium'}
							color="secondary"
							sx={{ width: { md: 'calc(50% - 1em)', xs: '100%' } }}
							label="Comment avez-vous entendu parler de nous ?"
						/>
					)}
				/>

				<Stack width="100%" position="relative">
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<CustomTextField
								{...field}
								size={isPhone ? 'small' : 'medium'}
								color="secondary"
								multiline
								rows="6"
								sx={{
									width: '100%',
								}}
								label="Parlez-nous un peu de votre projet"
							/>
						)}
					/>

					{!isPhone && (
						<CustomFormButton type="submit">
							{submitLabels[contactStatus]}
						</CustomFormButton>
					)}
				</Stack>
				{isPhone && (
					<CustomFormButtonResponsive type="submit">
						{submitLabels[contactStatus]}
					</CustomFormButtonResponsive>
				)}
			</Stack>
		</>
	);
}
