import * as React from 'react';
import { Link } from 'gatsby';
import Obfuscate from 'react-obfuscate';
import whiteLogo from '../../../images/white_logo.svg';
import whiteIcon from '../../../images/white_icon.svg';
import ContactForm from '../../../modules/ContactForm';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const NewsLetterTextField = styled(TextField)({
	'input': {
		color: 'white',
	},
	'label:not(.Mui-focused)': {
		color: 'white',
	},
	'.MuiInputBase-root:not(.Mui-focused)': {
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: 'white',
			borderRadius: '0',
		},
	},
	'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
		border: 'solid white 1px',
		borderRadius: '0',
	},
});

export default function Footer() {
	const theme = useTheme();
	const isPhone = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Stack
			sx={{ backgroundColor: 'primary.main', overflow: 'hidden' }}
			alignItems="center"
		>
			<Stack
				maxWidth="xl"
				padding={{ md: '2em 2em 0', xs: '4em 1em 1em' }}
				alignItems="center"
				gap="2em"
				width="100%"
			>
				{!isPhone && <ContactForm />}

				<Stack
					flexDirection={{ md: 'row', xs: 'column' }}
					justifyContent="space-between"
					alignItems="center"
					textAlign={{ xs: 'center', md: 'start' }}
					width="100%"
					position="relative"
					marginTop={{ md: '5em', xs: '0' }}
					gap="4em"
					paddingX="1em"
				>
					<Box
						component="img"
						alt="Logo"
						src={whiteLogo}
						width={{ md: '20%', xs: '100%' }}
					/>
					<Stack
						flexDirection="row"
						justifyContent="space-between"
						textAlign="start"
						width={{ md: 'auto', xs: '100%' }}
					>
						<Stack marginRight={{ md: '2em', xs: '0' }}>
							{[
								{
									link: '/services',
									label: 'Nos services',
								},
								{
									link: '/about',
									label: 'À propos',
								},
								{
									link: '/our-works',
									label: 'Nos réalisations',
								},
								{
									link: '/blog',
									label: 'Blog',
								},
								{
									link: '/faq',
									label: 'FAQ',
								},
							].map(({ link, label }) => (
								<Link key={label} to={link} style={{ textDecoration: 'none' }}>
									<Typography
										sx={{
											color: 'white',
											fontWeight: '500',
										}}
									>
										{label}
									</Typography>
								</Link>
							))}
						</Stack>

						<Stack marginBottom={{ md: '0', xs: '10%' }}>
							<Typography sx={{ color: 'white', fontWeight: '500' }}>
								Facebook
							</Typography>
							<Typography sx={{ color: 'white', fontWeight: '500' }}>
								Twitter
							</Typography>
							<Typography sx={{ color: 'white', fontWeight: '500' }}>
								Instagram
							</Typography>
							<Typography
								sx={{
									color: 'white',
									fontWeight: '500',
									a: { color: 'inherit', textDecoration: 'none' },
								}}
							>
								<Obfuscate email="contact@abbi.digital" />
							</Typography>
						</Stack>
					</Stack>
					{/* <Stack alignItems="start">
						<Typography color="white">Subscribe to the newsletter</Typography>
						<Stack flexDirection="row" border="solid white 2px">
							<NewsLetterTextField placeholder="Email Adress"></NewsLetterTextField>
							<Button
								sx={{
									'backgroundColor': 'white',
									'fontWeight': 'bold',
									'borderRadius': '0',
									'&:hover': {
										backgroundColor: 'lightgrey',
									},
								}}
							>
								Submit
							</Button>
						</Stack>
					</Stack> */}
					{!isPhone && (
						<Box
							component="img"
							alt="Logo"
							src={whiteIcon}
							marginRight="-8em"
							marginBottom={{ md: '-2em', xs: '-1em' }}
							width={{ xs: '100%', md: '30%' }}
						/>
					)}

					{isPhone && (
						<Stack
							flexDirection="row"
							justifyContent="space-between"
							textAlign="start"
							width="100%"
						>
							<Typography sx={{ color: 'white', fontSize: '10px' }}>
								©2023 abbi digital. All rights reserved.
							</Typography>
							<Typography sx={{ color: 'white', fontSize: '10px' }}>
								Terms & Conditions
							</Typography>
						</Stack>
					)}
				</Stack>
			</Stack>
		</Stack>
	);
}
