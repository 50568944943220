import React from 'react';
import { Link } from 'gatsby';
import logo from '../../../images/navbar_logo.svg';
import ContactForm from '../../../modules/ContactForm';
import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';

const StyledLink = styled(Link)(({ theme }) => ({
	'color': 'black',
	'&.active': {
		color: theme.palette.primary.main,
	},
}));

const pages = [
	{
		link: '/services',
		label: 'Nos services',
	},
	{
		link: '/about',
		label: 'À propos',
	},
	// {
	// 	link: '/our-works',
	// 	label: 'Nos réalisations',
	// },
	// {
	// 	link: '/blog',
	// 	label: 'Blog',
	// },
	// {
	// 	link: '/faq',
	// 	label: 'FAQ',
	// },
];

const Navbar: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = React.useState<null | HTMLElement>(null);
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
		setIsMenuOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setIsMenuOpen(null);
	};

	return (
		<AppBar
			position="fixed"
			sx={{
				backgroundColor: 'white',
				padding: { md: '2em 4em', xs: '0.5em 1em' },
				width: '100%',
				alignItems: { md: 'center', xs: 'start' },
				boxShadow: 'none',
			}}
		>
			<Toolbar
				disableGutters
				sx={{
					flexDirection: 'row',
					justifyContent: 'space-between',
					maxWidth: 'xl',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<Box component="a" href="/" display="flex">
					<Box
						component="img"
						alt="Logo"
						src={logo}
						className="logo"
						width={{ xs: '60%', md: '100%' }}
					/>
				</Box>
				<Box display={{ xs: 'flex', md: 'none' }}>
					<IconButton size="small" onClick={handleOpenMenu} color="primary">
						<MenuIcon />
					</IconButton>
					<Drawer
						id="menu-appbar"
						open={Boolean(isMenuOpen)}
						onClose={handleCloseMenu}
					>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							height="100%"
							paddingX="5%"
							width="250px"
						>
							<MenuItem key="logo" onClick={handleCloseMenu}>
								<Box component="a" href="/">
									<Box
										component="img"
										alt="Logo"
										src={logo}
										className="logo"
										width="100%"
									/>
								</Box>
							</MenuItem>
							{pages.map(({ link, label }) => (
								<MenuItem key={label} onClick={handleCloseMenu}>
									<StyledLink
										to={link}
										activeClassName="active"
										style={{ textDecoration: 'none' }}
									>
										<Typography sx={{ fontWeight: '600' }}>{label}</Typography>
									</StyledLink>
								</MenuItem>
							))}
							<MenuItem key="contact">
								<Button
									variant="contained"
									className="link__contact"
									onClick={() => setIsModalOpen(true)}
								>
									Contactez-nous
								</Button>
							</MenuItem>
							<Modal
								open={isModalOpen}
								onClose={() => setIsModalOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Box
									width="80%"
									sx={{
										backgroundColor: '#5344FA',
										padding: '1.5em',
									}}
								>
									<ContactForm />
								</Box>
							</Modal>
						</Box>
					</Drawer>
				</Box>

				<Box
					className="navbar-links"
					flexDirection="row"
					gap="2em"
					alignItems="center"
					display={{ xs: 'none', md: 'flex' }}
				>
					{pages.map(({ link, label }) => (
						<StyledLink
							key={label}
							to={link}
							activeClassName="active"
							style={{ textDecoration: 'none' }}
						>
							<Typography sx={{ fontWeight: '600' }}>{label}</Typography>
						</StyledLink>
					))}
					<Button variant="contained" className="link__contact" href="#contact">
						Contactez-nous
					</Button>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
